<template>
  <section class="app">
    <div class="warning">
      {{$t('warning', { localStart, localEnd })}}
    </div>
    <router-view />
    <Modal :visibility="showOpinion">
      <Opinion @end="handleSurveyEnd" @later="handleModalClose" />
    </Modal>
    <!-- LOADER -->
    <Loader />
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'; // Added mapState for token
import Modal from './components/Modal.vue';
import Loader from './components/Loader.vue';
import Opinion from './Opinion.vue';

export default {
  components: {
    Loader,
    Modal,
    Opinion
  },
  data() {
    return {
      showOpinion: false,
      utcStart: new Date("2025-04-06T09:00:00Z"),
      utcEnd: new Date("2025-04-06T10:00:00Z"),
    };
  },
  computed: {
    ...mapGetters(['isAppLoading']),
    ...mapState(['accessToken']), // Assuming access token is in Vuex state
    getAppVersion() {
      return process.env.VUE_APP_VERSION;
    },
    localStart() {
      return this.utcStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    localEnd() {
      return this.utcEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    isOnAuthPage() {
      const excludedRoutes = ['signin', 'signup', 'resetpassword'];
      return excludedRoutes.includes(this.$route.name) || excludedRoutes.some(route => this.$route.path.includes(route));
    },
    isTokenValid() {
      // Check if access token exists and is valid (simplified example)
      if (!this.accessToken) return false;

      try {
        const tokenPayload = JSON.parse(atob(this.accessToken.split('.')[1])); // Decode JWT payload
        const expiry = new Date(tokenPayload.exp * 1000); // Convert expiration timestamp to Date
        return expiry > new Date(); // Token is valid if expiry is in the future
      } catch (e) {
        return false; // Invalid token format
      }
    },
  },
  mounted() {
    this.checkSurveyStatus();
  },
  watch: {
    '$route'(to, from) {
      // Re-check survey status when route changes (e.g., user navigates away from auth pages)
      this.checkSurveyStatus();
    },
  },
  methods: {
    checkSurveyStatus() {
      // Only proceed if not on auth pages and token is valid
      if (this.isOnAuthPage || !this.isTokenValid) {
        this.showOpinion = false;
        return;
      }

      const surveyData = JSON.parse(localStorage.getItem('surveyStatus') || '{}');
      const now = new Date();

      // Check if survey was completed
      if (surveyData.completed) {
        this.showOpinion = false; // Never show again if completed
        return;
      }

      // Check if user has never seen the survey
      if (!surveyData.lastSeen) {
        this.showOpinion = true; // Show modal for the first time
        localStorage.setItem('surveyStatus', JSON.stringify({ lastSeen: now.toISOString() }));
        return;
      }

      // Check if 24 hours have passed since last seen
      const lastSeen = new Date(surveyData.lastSeen);
      const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

      if (now - lastSeen >= oneDayInMs) {
        this.showOpinion = true; // Show modal again after 24 hours
        localStorage.setItem('surveyStatus', JSON.stringify({ lastSeen: now.toISOString() }));
      } else {
        this.showOpinion = false; // Don't show if less than 24 hours have passed
      }
    },
    handleSurveyEnd(action) {
      const surveyData = JSON.parse(localStorage.getItem('surveyStatus') || '{}');
      const now = new Date();

      if (action === 'completed') {
        // User completed the survey
        localStorage.setItem('surveyStatus', JSON.stringify({ completed: true, lastSeen: now.toISOString() }));
      } else if (action === 'later') {
        // User chose to answer later
        localStorage.setItem('surveyStatus', JSON.stringify({ lastSeen: now.toISOString() }));
      }

      this.showOpinion = false; // Close the modal
    },
    handleModalClose() {
      // If the modal is closed without an explicit action (e.g., user clicks outside or closes it)
      const surveyData = JSON.parse(localStorage.getItem('surveyStatus') || '{}');
      const now = new Date();
      localStorage.setItem('surveyStatus', JSON.stringify({ lastSeen: now.toISOString() }));
      this.showOpinion = false; // Close the modal
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/_shared.scss';
@import '@/assets/_grid.scss';

*{
  font-family: 'Gilroy';
}

body{
  background: $light-gray;
}

html, body, #app, .app {
  height: 100%;
}

.warning{
  padding: 10px;
  background: $red;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: white;
}
</style>