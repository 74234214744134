<template>
  <div class="survey">
    <!-- Step Indicator -->
    <div class="step-indicator">
      {{ $t('stepIndicator', { current: currentStep, total: 3 }) }}
      <div class="progress-bar">
        <div
          class="progress"
          :style="{ width: (currentStep / 3) * 100 + '%' }"
        ></div>
      </div>
    </div>

    <h3 class="survey-title">{{ $t('surveyTitle') }}</h3>
    <h2>{{ currentQuestion }}</h2>
    <p class="survey-note">
      {{ $t('surveyNote') }}
    </p>

    <textarea
      v-model="responses[currentStep - 1]"
      :placeholder="$t('placeholder')"
      class="survey-input"
      ref="textarea"
      @input="adjustTextareaHeight"
      maxlength="2000"
    ></textarea>
    <p class="char-counter">{{ responses[currentStep - 1].length }}/2000</p>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    <div class="buttons">
      <GrayButton
        gray
        @click="answerLater"
        v-show="currentStep == 1"
      >
        {{ $t('answerLater') }}
      </GrayButton>
      <Button
        v-if="currentStep < 3"
        @click="nextStep"
        :disabled="!isResponseValid"
      >
        {{ $t('next') }}
      </Button>
      <Button
        v-if="currentStep === 3"
        @click="submitSurvey"
        :disabled="!isResponseValid"
      >
        {{ $t('submit') }}
      </Button>
    </div>
    <p v-if="message" class="message">{{ message }}</p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Button from "./components/RedButton.vue";
import GrayButton from "./components/GrayButton.vue";

export default {
  name: "SurveyComponent",
  components: {
    Button,
    GrayButton
  },
  data() {
    return {
      currentStep: 1,
      responses: ["", "", ""],
      questions: [
        this.$t('question1'),
        this.$t('question2'),
        this.$t('question3'),
      ],
      message: "",
      errorMessage: "",
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentStep - 1];
    },
    isResponseValid() {
      const currentResponse = this.responses[this.currentStep - 1];
      return currentResponse && currentResponse.length >= 3;
    },
  },
  methods: {
    ...mapActions(["createOpinion", "createNotification"]),
    nextStep() {
      if (this.isResponseValid) {
        this.createOpinion({
          text: `${this.currentStep}` + this.responses[this.currentStep - 1],
        });
        this.currentStep++;
        this.errorMessage = ""; // Clear any previous error message
      } else {
        this.errorMessage = this.$t('errorMinChars');
      }
    },
    async submitSurvey() {
      if (this.isResponseValid) {
        this.createOpinion({
          text: "3" + this.responses[this.currentStep - 1],
        });
        const notification = {
          id: new Date(),
          heading: this.$t('thankYouNotification'),
          style: "success",
          lifetime: 3000,
        };
        this.createNotification(notification);
        this.$emit("end");
      } else {
        this.errorMessage = this.$t('errorMinChars');
      }
    },
    adjustTextareaHeight(event) {
      const textarea = event.target;
      textarea.style.height = "auto";
      textarea.style.height = Math.min(textarea.scrollHeight, 200) + "px";
    },
    answerLater() {
      this.currentStep = 1;
      this.responses = ["", "", ""];
      this.message = this.$t('answerLaterMessage');
      this.errorMessage = ""; // Clear any error message
      this.$emit('later')
    },
  },
};
</script>

<style lang="scss" scoped>
$red: #ff0000;

.survey {
  max-width: 400px;
  margin: 20px auto;
}

.step-indicator {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
  text-align: left;

  .progress-bar {
    height: 5px;
    background-color: #eee;
    border-radius: 3px;
    margin-top: 5px;

    .progress {
      height: 100%;
      background-color: $red;
      border-radius: 3px;
      transition: width 0.3s ease-in-out;
    }
  }
}

.survey-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: $red;
  text-align: left;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.survey-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  overflow: hidden;
  min-height: 150px;
  max-height: 200px;
  box-sizing: border-box;
}

.char-counter {
  font-size: 12px;
  color: #777;
  text-align: right;
  margin-top: 5px;
}

.survey-note {
  font-size: 14px;
  color: #666;
  text-align: left;
  margin: 0;
  padding-bottom: 10px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.later-button {
  background-color: #ccc;
  color: #333;
  border: none;

  &:hover {
    background-color: #bbb;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
}

.message {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
  text-align: center;
}

.error-message {
  color: $red;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
}
</style>