import axios from 'axios';

const initialState = () => {
  return {
    surveys:[],
    constants:{
      MAX_OPTION_COUNT: 20,
      MAX_QUESTION_COUNT: 100,
      MAX_OPTION_ORDER: 32767,
      MAX_QUESTION_ORDER: 32767,
      MIN_OPTION_ORDER: -32768,
      MIN_QUESTION_ORDER: -32768,
      OptionTypes: {
          RADIO: "RADIO",
          CHECKBOX: "CHECKBOX",
          RATING: "RATING",
          TEXT: "TEXT"
      },
      SurveySlugRegExp: /^[A-Z0-9]{1,128}$/
    },
    isOpenMusicLabelOffer: false
  };
};

const survey = {
  state: () => initialState(),
  actions: {
    createOpinion(_, text){
      return new Promise((resolve, reject) => {
        axios.post(`/survey/opinion`,{text}).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchMySurveyAnswers({commit},survey){
      return new Promise((resolve, reject)=>{
        axios.get(`/me/survey/${survey.uuid}/answers`).then(response => {
          // commit('addAnswer',{survey, answer:response.data})
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchConstants: function ({commit}){
      return new Promise((resolve, reject) => {
        axios.get('/constants').then(response => {
          commit('setSurveyConstants',response.data)
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      }); 
    },
    fetchSurveyBySlug({commit},slug){
      return new Promise((resolve, reject) => {
        axios.get(`/survey/slug/${slug}`).then(response => {
          commit('setSurvey', response.data)
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      }); 
    },
    createAnswer({commit}, {option, survey, valueBool, valueText, valueNumber}){
      return new Promise((resolve, reject) => {
        axios.post(`/option/${option.uuid}/answer`,{valueBool,valueText,valueNumber}).then(response =>{
          resolve(response)
        }).catch(error => reject(error))
      })
    }
  },
  mutations: {
    updateMusicLabelOffer(state, isOpen){
      state.isOpenMusicLabelOffer = isOpen
    },
    setSurvey(state,survey){
      const idx = state.surveys.findIndex(stateSurvey => stateSurvey.uuid == survey.uuid )
      if(idx != -1){
        state.surveys[idx] = {...survey}
      }else{
        state.surveys.push(survey)
      }
    },
    setSurveyConstants(state, constants){
      state.constants = constants
      const regexParts = constants.SurveySlugRegExp.match(/\/(.+)\/([a-z]*)/i);
      state.constants.SurveySlugRegExp = new RegExp(regexParts[1], regexParts[2]);
    },

    // addAnswerList({},{survey, question, option, answers}){
    //   answers.forEach(answer => {
    //     this.addAnswer.push({survey, question, option, answer})
    //   });
    // },

    // addAnswer(state,{survey, question, option, answer}){
    //   const stateSurvey = state.surveys.find(stateSurvey => stateSurvey.uuid == survey.uuid)
    //   const surveyQuestion = stateSurvey.questions.find(surveyQuestion => surveyQuestion.uuid == question.uuid )
    //   const questionOption = surveyQuestion.options.find(questionOption => questionOption.uuid = option.uuid)
    //   const optionAnswer = questionOption.options.answers.find(optionAnswer => optionAnswer.uuid = answer.uuid)
    //   if(!optionAnswer){
    //     questionOption.options.push(answer)
    //   }
    // }
  },
  getters: {
    surveys: state => state.surveys,
    surveyBySlug: state => slug => state.surveys.find(survey => survey.slug == slug),
    isOpenMusicLabelOffer: state => state.isOpenMusicLabelOffer,
  },
};

export default survey;
